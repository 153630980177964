<template>
    <v-container>
        <v-row>
            <v-col cols="12" class="title">
                <h1>MAIS VENDIDOS</h1>
            </v-col>
        </v-row>
        <v-row>
            <v-container class="container-products">
                <v-row>
                    <v-col v-for="product in products" :key="product.id" cols="12" sm="6" md="4" lg="4">
                        <ProductCard :product="product" />
                    </v-col>
                </v-row>
            </v-container>
        </v-row>
        <v-row>
            <v-col cols="12" class="btn-view-all">
                <button class="lda-btn lda-btn-black text-md">VEJA TODOS OS PRODUTOS</button>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import ProductCard from '@/components/default/ProductCard.vue';
export default{
    name: "HomePageProducts",
    components: {
        ProductCard
    },
    props: {
        products: {
            type: Array,
            required: true
        }
    }
}
</script>

<style scoped>
    .container-products {
        width: 100%;
    }
    @media (min-width: 1264px) {
        .container-products {
            max-width: 1185px;
        }
    }

    @media (min-width: 960px) {
        .container-products {
            max-width: 900px;
        }
    }
    @media (min-width: 600px) {
        
    }

    .title {
        margin-top: 20px;
        margin-bottom: 30px;
        
    }
    .title h1{
        font-size: 2em;
        font-weight: 700;
        color: var(--color-primary);
        font-family: "Tomorrow", sans-serif !important;
        text-align: center;
    }
    .btn-view-all{
        padding-top: 15px;
        padding-bottom: 15px;
    }
</style>
