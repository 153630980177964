<template>
    <v-container class="banner-slide">
        <v-row>
            <v-col cols="12">
                <v-carousel
                    hide-delimiters
                    cycle
                    show-arrows-on-hover
                    height="auto"
                >
                    <v-carousel-item
                        v-for="(item,i) in items"
                        :key="i"
                    >
                        <v-img
                            :src="item.src"
                        ></v-img>
                    </v-carousel-item>
                </v-carousel>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'BannerSlide',
    data() {
        return {
            items: [
                { src: require('@/assets/img/banners/banner1.png'), width: 1920, height: 1080 },
                { src: require('@/assets/img/banners/banner2.png'), width: 1920, height: 1080 },
                { src: require('@/assets/img/banners/banner3.png'), width: 1920, height: 1080 },
            ],
        };
    },
};
</script>

<style scoped>

</style>