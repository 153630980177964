<template>
    <v-container>
        <v-row>
            <v-col>
                <div class="artist-card">
                    <div class="body">
                        <img :src="productShow.image" alt="">
                        <v-icon class="heart" ref="heart" @click="handleFavorite(iconName == 'far fa-heart' ? true : false)">
                            {{ iconName }}
                        </v-icon>
                        <h5>{{ productShow.name }}</h5>
                        <small>{{ productShow.description }}</small>
                    </div>
                    <div class="footer">
                        <button class="lda-btn lda-btn-default"> {{ productShow.value.toLocaleString('pt-BR', { style:  'currency', currency: 'BRL' }) }}</button>
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'ProductCard',
    props: {
        product: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            productShow: {
                id: 0,
                name: '',
                image: '',
                description: '',
                value: 0
            },
            iconName: 'far fa-heart'
        }
    },
    mounted() {
        this.productShow = this.product;
        this.iconName = this.isFavorite() ? 'fa fa-heart' : 'far fa-heart';
        this.productShow.name = this.product.name.length > 20 ? this.product.name.substring(0, 20) + '...' : this.product.name;
    },
    methods:{
        handleFavorite(e){
            if(e){
                let favorites = localStorage.getItem('favorites');
                if(favorites){
                    favorites = JSON.parse(favorites);
                    favorites.push(this.product.id);
                    localStorage.setItem('favorites', JSON.stringify(favorites));
                }else{
                    localStorage.setItem('favorites', JSON.stringify([this.product.id]));
                }
                this.iconName = 'fa fa-heart';
            }else{
                let favorites = localStorage.getItem('favorites');
                if(favorites){
                    favorites = JSON.parse(favorites);
                    favorites = favorites.filter(favorite => favorite !== this.product.id);
                    localStorage.setItem('favorites', JSON.stringify(favorites));
                }
                this.iconName = 'far fa-heart';
            }
        },
        isFavorite(){
            let favorites = localStorage.getItem('favorites');
            if(favorites){
                favorites = JSON.parse(favorites);
                return favorites.includes(this.product.id);
            }else{
                return false;
            }
        }
    }
}
</script>

<style scoped>
    .artist-card img {
        width: 100%;
        border-radius: 10px;
    }
    .artist-card h5 {
        margin-top: 10px;
        margin-bottom: -6px;
        font-size: 1em;
        font-family: "Tomorrow", sans-serif;
    }
    .artist-card .body{
        padding-bottom: 10px;
    }

    .artist-card .heart{
        width: 25px;
        float: right;
        margin-top: 15px;
        color: var(--color-primary);
    }
</style>