<template>
    <v-app>
        <NavBar />
        <BannerSlide />
        <HomePageArtists :artists="artists" />
        <HomePageProducts :products="products" />
    </v-app>
</template>

<script>
import NavBar from '@/components/default/NavBar.vue';
import BannerSlide from '@/components/default/BannerSlide.vue';
import HomePageArtists from '@/components/artists/HomePageArtists.vue';
import HomePageProducts from '@/components/products/HomePageProducts.vue';
export default {
    name: 'HomeView',
    components: {
        NavBar,
        BannerSlide,
        HomePageArtists,
        HomePageProducts,
    },
    data() {
        return {
            artists: [
                {
                    id: 1,
                    name: 'MC PAIVA',
                    image: require('@/assets/img/artists/artista1.png'),
                    role: 'CANTOR',
                },
                {
                    id: 2,
                    name: 'MC PAULIN DA CAPITAL',
                    image: require('@/assets/img/artists/artista2.png'),
                    role: 'CANTOR'
                },
                {
                    id: 3,
                    name: 'MC LIPI',
                    image: require('@/assets/img/artists/artista3.png'),
                    role: 'CANTOR'
                }
            ],
            products:[
                {
                    id: 1,
                    name: 'CAMISETA MC PAIVA',
                    image: require('@/assets/img/products/product1.png'),
                    description: 'CAMISETA MC PAIVA',
                    value: 50.00
                },
                {
                    id: 2,
                    name: 'CAMISETA MC PAULIN DA CAPITAL',
                    image: require('@/assets/img/products/product2.png'),
                    description: 'CAMISETA MC PAULIN DA CAPITAL',
                    value: 50.00
                },
                {
                    id: 3,
                    name: 'CAMISETA MC LIPI',
                    image: require('@/assets/img/products/product3.png'),
                    description: 'CAMISETA MC LIPI',
                    value: 50.00
                },
                {
                    id: 4,
                    name: 'CAMISETA MC PAIVA',
                    image: require('@/assets/img/products/product1.png'),
                    description: 'CAMISETA MC PAIVA',
                    value: 50.00
                },
                {
                    id: 5,
                    name: 'CAMISETA MC PAULIN DA CAPITAL',
                    image: require('@/assets/img/products/product2.png'),
                    description: 'CAMISETA MC PAULIN DA CAPITAL',
                    value: 50.00
                },
                {
                    id: 6,
                    name: 'CAMISETA MC LIPI',
                    image: require('@/assets/img/products/product3.png'),
                    description: 'CAMISETA MC LIPI',
                    value: 50.00
                }
            ]
        };
    },
};
</script>

<style scoped>
    
</style>